//sort items alphabetically
function sortProductsList(){
    //console.log("sortiram listu");
    var productsContainer = $(".listing");

    var singleProduct = productsContainer.children('.product').get();

    singleProduct.sort(function(a, b) {
        return $(a).find(".product-name").text().toUpperCase().localeCompare($(b).find(".product-name").text().toUpperCase());

    });

    $.each(singleProduct, function(index, item) {
        productsContainer.append(item); 
    });
}

export { sortProductsList };
