import {imageExists} from './helpers';

function listProducts(data, index, declarationsLang){
    var product = data.records[index]; //single product
    var colors = product.fabric_color_number.split(","); //all colors from single product
    var favColor = product.fabric_color_img;

    var lang = localStorage.getItem('language');

    var randomColor = colors[Math.floor(Math.random()*colors.length)]; //take random color from the list of colors

    var fabricImgPath;
    //if favorite color is defined, show it, if not take the first color from the list as "main color"
    if ((favColor == 0) || (favColor == "0") || favColor == undefined || favColor == "undefined" || favColor == null){
        //console.log("nije definirano");
        favColor = colors[0];
        fabricImgPath = './img/fabrics/thumbs/'+product.fabric_name.replace(/\s/g, '')+'_'+favColor+'_pl.jpg';
        
    }
    else{
        //console.log("definirano");
        fabricImgPath = './img/fabrics/thumbs/'+product.fabric_name.replace(/\s/g, '')+'_'+favColor+'_pl.jpg';                                                                                                                                                                                                                                                                                                                                                                                                                                                            
    }
    
    var matchedColors = [];
    var colorMatch = {
        class: '',
        number: ''
    }
    var treatmentClass = setTreatmentType(product.fabric_treatment, treatmentClass);
    var treatmentClass2 = setTreatmentType(product.fabric_treatment2, treatmentClass);
    var treatmentClass3 = setTreatmentType(product.fabric_treatment3, treatmentClass);

    var infoIcon = setTreatmentAvailability(product.fabric_treatment, product.fabric_treatment_available, lang);
    var infoIcon2 = setTreatmentAvailability(product.fabric_treatment2, product.fabric_treatment2_available, lang);
    var infoIcon3 = setTreatmentAvailability(product.fabric_treatment3, product.fabric_treatment3_available, lang);

    var newClass = checkifNewFabric(product.fabric_new, newClass);
    if(($(".check-color:checkbox:checked").length > 0)){
        var colorMatch = checkifColorMatches(colors, data.colors, matchedColors);
        //TODO: ako nema boje kad se u filteru klikne npr crna , prikazati no-image
        //if selected color exist and if none of the color checkboxes are selected, set the image of selected color
        if(colorMatch.number != ''){
            favColor = colorMatch.number;
            
            fabricImgPath = './img/fabrics/thumbs/'+product.fabric_name.replace(/\s/g, '')+'_'+favColor+'_pl.jpg';  

                $(".listing").append(
                    '<div class="product '+colorMatch.class+'">'+
                        '<a href="./products-detail.html?fabric_name='+product.fabric_name.replace(/\s/g, '')+'&fabric_color='+favColor+'" target="_blank">'+
                            '<span class="'+newClass+'">New</span>'+
                            '<span class="product-label '+treatmentClass+'">'+ infoIcon + product.fabric_treatment+'</span>'+
                            '<span class="product-label product-label--second '+treatmentClass2+'">'+ infoIcon2 +product.fabric_treatment2+'</span>'+
                            '<span class="product-label product-label--third '+treatmentClass3+'">'+ infoIcon3 +product.fabric_treatment3+'</span>'+ 
                            '<span class="product-thumb"><img src="'+fabricImgPath+'" alt="fabric-thumb"></span> '+
                            '<span class="product-name">'+product.fabric_name +'</span>'+
                        '</a>'+
                        '<span class="product-type" data-lang="'+product.fabric_type+'">'+product.fabric_type+'</span>'+
                    '</div>'
                );
        }    
    }

    else{
        
        var declarationsPage = $(".listing--declarations");
         var newDeclarationsPage = $(".new-declarations-listing");
/* 
        if (declarationsLang){

        }
        else{
            declarationsLang = "EN";
        } */

        var href = "./products-detail.html?fabric_name="+product.fabric_name.replace(/\s/g, '')+"&fabric_color="+favColor+"";
        if(declarationsPage.length > 0){
            href = "api/getDeclarations"+declarationsLang+".php?material="+product.fabric_name+"";
            if(newDeclarationsPage.length > 0){
                href = "api/getNewDeclarations.php?material="+product.fabric_name+"";
            }
        }

        $(".listing").append(
            '<div class="product '+colorMatch.class+'">'+
                '<a href="'+href+'" target="_blank">'+
                    '<span class="'+newClass+'">New</span>'+
                    '<span class="product-label '+treatmentClass+'">'+infoIcon+product.fabric_treatment+'</span>'+
                    '<span class="product-label product-label--second '+treatmentClass2+'">'+infoIcon2+product.fabric_treatment2+'</span>'+
                    '<span class="product-label product-label--third '+treatmentClass3+'">'+ infoIcon3 +product.fabric_treatment3+'</span>'+ 
                    '<span class="product-thumb"><img src="'+fabricImgPath+'" alt="fabric-thumb"></span> '+
                    '<span class="product-name">'+product.fabric_name +'</span>'+
                '</a>'+
                '<span class="product-type" data-lang="'+product.fabric_type+'">'+product.fabric_type +'</span>'+
            '</div>'
        );
    }

    //check if img exists, if not, apply no image
    imageExists(fabricImgPath, function(exists) {
        if(exists == false){
            var x = $("img[src$='"+fabricImgPath+"']");
            x.attr("src", './img/fabrics/thumbs/_no-image.jpg');
        }
    }); 
   
}

function setTreatmentAvailability(treatment, treatmentAvailability, lang){
    var infoIcon = "",
        infoMsg = "";
    if(treatmentAvailability == "available-to-order"){
        if(treatment == "Water repellent" || treatment == "Stain insensitive"){
            if(lang == "de"){
                infoMsg = "* Nachbehandlung in Deutschland, Stain insensitive and Water repellent in 2-3 Wochen verfügbar";
            }
            if(lang == "hr"){
                infoMsg = "* Naknadni tretman u Njemačkoj, Stain insensitive and Water repellent dostupni za 2-3 tjedna";
            }
            else{
                infoMsg = "* After treatment in Germany, Stain protection and Water repellent treatment available in 2-3 weeks";
            }
        }
        else if(treatment == "Fire retardant"){
            if(lang == "de"){
                infoMsg = "* Nachbehandlung in Deutschland, FR-Normen EN ISO 1021 1 + 2 und Crib5 in 2-3 Wochen verfügbar";
            }
            else if(lang == "hr"){
                infoMsg = "* Naknadni tretman u Njemačkoj, FR standardi EN ISO 1021 1 + 2 i Crib5 dostupni za 2-3 tjedna ";
            }
            else {
                infoMsg = "* After treatment in Germany, FR standards EN ISO 1021 1+2 and Crib5 available in 2-3 weeks";
            }
        }
        infoIcon = '<img class="info-icon show-info-icon" src="./img/icons/icon-info.png" data-toggle="tooltip" data-placement="top" title="'+infoMsg+'">';
    }
    return infoIcon;
}

function setTreatmentType(treatment, treatmentClass){
    switch (treatment) { 
        case 'Fire retardant': 
            treatmentClass = "fire-retardant";
            break;
        case 'Water repellent': 
            treatmentClass = "water-repellent";
            break;
        case 'Natural fiber': 
            treatmentClass = "natural-fiber";
            break;		
        case 'Outdoor Superior': 
            treatmentClass = "outdoor-superior";
            break;
        case 'Outdoor Junior': 
            treatmentClass = "outdoor-junior";
            break;
        case 'Stain insensitive': 
            treatmentClass = "stain-insensitive";
            break;
        case 'Scratch insensitive': 
            treatmentClass = "scratch-insensitive";
            break;
        case 'Quilted': 
            treatmentClass = "quilted";
            break;
        case 'Proclean': 
            treatmentClass = "proclean";
            break;
        case 'Printed': 
            treatmentClass = "printed";
            break;
        case 'OEKO-TEX': 
            treatmentClass = "oeko-tex";
            break;
        default:
            treatmentClass = "";
    }
    return treatmentClass;
}

function checkifNewFabric(fabricNew, newClass){
    if(fabricNew == 1){
        newClass = "product-new";
    }
    else{
        newClass="product-old";
    }
    return newClass;
}

function checkifColorMatches(productColors, selectedColors, matchedColors){

    //TODO: sada se šalje samo klasa prema kojoj se skriva/prikazuje tkanina ako matchira boja
    //todo da se pošalje i broj boje te da se prikaže točno ta slika te boje- to je matchedColors array
    //console.log(productColors);
    //console.log(selectedColors);

    

    $(selectedColors).each(function( index ) {
        const found = selectedColors[index].some(r=> productColors.includes(r))
        //console.log(found);

        if(found === true){
            matchedColors.push(selectedColors[index][0]);
        }

    });

    var color = {};
    if (typeof matchedColors == 'undefined' || matchedColors.length == 0) {
        //console.log("doesn't match any color");
        //return matchedColors;
        color = {
            class: 'no-color-match',
            number: ''
        }
        return color;
    }
    else{
        //console.log("MATCH!");    
        //return matchedColors;
        color = {
            class: '',
            number: matchedColors[0]
        }
        return color;

    }
    
    



}

export { listProducts };