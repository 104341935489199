//plugins
import 'cookieconsent';
import "jquery";
import 'bootstrap';

//main css
import '../scss/main.scss';

//global js
import './paths';
import './g_cookies';
//napraviti code splitting za photoswipe (kao na valamaru data on load module)
import './g_photoswipe';
import {translate} from './translate';

//products js
import './products/read-products';
import './products/filter-products';
import './products/read-one-product';
import './products/treatment-products';
import './products/create-product';
import './products/upload-image';


//https://medium.com/@estherfalayi/webpack-4-nicknamed-legato-is-out-and-the-community-is-still-on-the-hots-for-it-i-am-too-e9bee3d2e4a

$( document ).ready(function() {

    var userLang = navigator.language || navigator.userLanguage; 
    var userLang = userLang.slice(0,2);
    // create object, load JSON file, default to 'nl', and callback to refreshLabels when ready loading
    var translating;
    var lang;
    var langFromStorage = localStorage.getItem('language');

    //ako je user selektirao jezik
    if(langFromStorage){
        lang = langFromStorage;
        $("html").attr("lang", lang); 
        $(".selected-lang a").text(lang);
        $(".selected-lang a").val(lang);
    }
    //ako user nije selektirao jezik, uzmi defaultni jezik od userovog browsera
    else if(!langFromStorage && userLang){
        lang = userLang;
    }
    //ako nije selektirao jezik niti nema default u browseru, uzmi default iz html taga (en)
    else if(!langFromStorage && !userLang){
        lang = $("html").attr("lang");
    }
    //ako ne postoji defaultni jezik definiran u html tagu, postavi ga na en
    else if (lang == undefined || lang == ""){
        lang = "en";
    }

    changeDownloadLinks(lang);

    $(".selected-lang").on('click', function() {
        if ($(window).width() > 992) {
           $(".lang-dropdown-list").toggleClass("active");
        }
    });
    //on M - screens always show
    if ($(window).width() <= 992) {
        $(".lang-dropdown-list").addClass("active");    
        $(".login-form").addClass("active");    
    }

    $(".language").click(function(){
        lang = $(this).attr('value');
        localStorage.setItem('language', lang);
        $("html").attr("lang", lang); 
        $(".selected-lang a").text(lang);
        $(".selected-lang a").val(lang);

        changeDownloadLinks(lang);

        if ($(window).width() > 992) {
            $(".lang-dropdown-list").removeClass("active");    
        }
        translating = new translate('./lang/lang.'+lang+'.json', lang, refreshLabels);
    });

    translating = new translate('./lang/lang.'+lang+'.json', lang, refreshLabels);


    $('#headerSlider').carousel({
        interval: 6000
    });


    function changeDownloadLinks(lang){
        $(".download-links a[data-href]").each(function() {

            var href = $(this).attr("data-href") + "_"+lang.toUpperCase()+".pdf";
            console.log(href);
            $(this).attr("href", href);
        });
    }
   
    function refreshLabels() {

        // loop through all document elements
        var allnodes = document.querySelectorAll("[data-lang]");

        for (var i=0, max=allnodes.length; i < max; i++) {
            // get id current elements
            var dataValue = allnodes[i].getAttribute('data-lang');

            // if data-lang exists, set get data-lang current elements
            if (dataValue != '') {
                allnodes[i].innerHTML  = translating.get(dataValue);
            };
        };
    }

    $(".login img").on('click', function() {
        $(".login-form").toggleClass("active");
    });


    //1. if you see this, congatulations, you know how to open a console, maybe you can steal textum declarations too?
    $("#login").click(function() {
        var user = $("#user").val();
        var pass = $("#pass").val();
        
        //2. if you can solve this, congrats! 
        if(user == String.fromCharCode(116,101,120,85,115,101,114) && pass == String.fromCharCode(116,101,120,80,97,115,115))
        {
            window.open('declarations.html', '_self')
        }
        else{
            $(".error").show();
            setTimeout(function(){
                $('.error').hide();// or fade, css display however you'd like.
            }, 3000);
        }
    });
        

    //close dropdowns on click outside
    $(document).click(function(event) { 
        var target = $(event.target);
        if(!target.closest('.login-form').length && !target.closest('.login').length && $('.login-form').is(":visible")) {
            $('.login-form').removeClass("active");
        }

        if(!target.closest('.lang-dropdown-list').length && !target.closest('.lang-dropdown').length && $('.lang-dropdown-list').is(":visible") && $(window).width() > 992) {
            $('.lang-dropdown-list').removeClass("active");
        }    
       /*  otvori zatvori sidenav ne radi zasad
        if(!target.parents('.sidenav').length && $('.sidenav').hasClass("open")) {
            $('.sidenav').removeClass("open");
        }             */
    });

    //HP navigation
    $( window ).scroll(function() {
        if($(".header").length){
            if($( window ).scrollTop()>100){
                $("nav").addClass("nav-fixed");
                $(".main-nav__logo").attr("src","img/textum-logo-dark.svg");
            }
            else{
                $("nav").removeClass("nav-fixed");
                $(".main-nav__logo").attr("src","img/textum-logo.png");
            }
        }
        
    });

    //HP navigation S
    $('.main-nav__hamburger').click(function(){
        $(this).toggleClass('open');
        $(".sidenav").toggleClass('open');
        if ($(window).width() < 768) {
            $("body").toggleClass("disable-scroll");           
        }
    });

    //HP animated headline
    var animationDelay = 5500;// set animation timing

    initHeadline();

    function initHeadline() {
    // initialise headline animation
    animateHeadline($('.animated-headline'));
    }

    function animateHeadline($headlines) {
    var duration = animationDelay;
    $headlines.each(function() {
        var headline = $(this);
        //trigger animation
        setTimeout(function(){ hideWord( headline.find('.is-visible').eq(0) ) }, duration);
    });
    }

    function hideWord($word) {
    var nextWord = takeNext($word);
        switchWord($word, nextWord);
        setTimeout(function(){ hideWord(nextWord) }, animationDelay);
    }

    function takeNext($word) {
    return (!$word.is(':last-child')) ? $word.next() : $word.parent().children().eq(0);
    }

    function switchWord($oldWord, $newWord) {
    $oldWord.removeClass('is-visible').addClass('is-hidden');
    $newWord.removeClass('is-hidden').addClass('is-visible');
    }
        

    //HP Events
    $(".divider button").click(function(){

        let activeBtn =  $(this);
        let activeEvent = activeBtn.data("event");

        $(".divider button").removeClass("active");
        $(".event-image").removeClass("active");
        $(".event-date").removeClass("active");

        activeBtn.addClass("active");
        $(".event-image[data-event='"+activeEvent+"'").addClass("active");
        $(".event-date[data-event='"+activeEvent+"'").addClass("active");

    });

    //HP Team
    $(".departments-list li").click(function(){
       let department = $(this).data("department");

       $(".departments-list li").removeClass("active");
       $(this).addClass("active");

       $(".employees--regular").removeClass("active");
       $(".employees--regular[data-department="+department+"]").addClass("active");
    });

    $(window).on("load resize",function(e){
        if ($(window).width() < 768) {
            $(".employees--regular").each(function() {
                let department = $(this).data("department");
                $(".departments-list li[data-department="+department+"]").append($(this));
            });
        }
    });

    //HP map L
    $("#locations_europe_map a area").click(function(){
        let country = $(this).data("country");
        $("#locations_europe .lokacije_tekst").removeClass("active");
        $("#locations_europe .lokacije_tekst[data-country='"+country+"']").addClass("active");
    });
    //HP map M
    $(".countries a").click(function(){
        let country = $(this).data("country");
        $("#locations_europe .lokacije_tekst").removeClass("active");
        $("#locations_europe .lokacije_tekst[data-country='"+country+"']").addClass("active");
    });
    //HP map S
    $( "select" ).change(function () {
        let country =  $( "select option:selected").val();
        $("#locations_europe .lokacije_tekst").removeClass("active");
        $("#locations_europe .lokacije_tekst[data-country='"+country+"']").addClass("active");
    });

    //Products
    $(".filter__label").click(function(){
        $(this).toggleClass("closed");
        $(this).siblings(".filter__elements").toggleClass("closed");
    });

    //Filter 
    if ($(window).width() < 768) {
        $(".filter").addClass("filter-sm-hide");

        //on mobile, default close labels
        $(".filter__label").addClass("closed");
        $(".filter__label").siblings(".filter__elements").addClass("closed");

        $(".btn-toggle-filter, .btn-filter, .btn-clear").click(function(){
            $(".filter").toggleClass("filter-sm-hide");
            $(".btn-toggle-filter").toggleClass("hide-filter");
            if($(".btn-toggle-filter").hasClass("hide-filter")){
                $(".btn-toggle-filter").html("<span>× hide filter</span>");
            }
            else{
                $(".btn-toggle-filter").html('<img src="./img/icons/icon-filter.png" alt="filter-icon"><span>Show Filter</span>');
            }
        });
        
     }

    //Search handler - filtrira prema nazivu proizvode koji su izlistani
    $("#searchInput").on("keyup", function() {
        var value = $(this).val().toLowerCase();
        $(".listing .product").filter(function() {
          $(this).toggle($(this).find(".product-name").text().toLowerCase().indexOf(value) > -1)
        });
    });

    $("#clearFilter").click(function(){
        //clears search input and resets list to initial
        $('input[type="search"]').val('');
        $(".listing .product").filter(function() {
            $(this).toggle($(this).find(".product-name").text().toLowerCase().indexOf('') > -1)
        });
        //remove all checkboxes
        $('input[type=checkbox]').removeAttr('checked');
        $('.check-type').prop('checked',false);
        $('.check-treatment').prop('checked',false);
        $('.check-color').prop('checked',false);
        //remove all filter tags
        $(".selected-filters").empty();
        $('.listing').empty();
    }); 



    //Product details
    $('.product-description-list li:visible:odd').addClass("colored");
    $('.product-description-list li:last-child').removeClass("colored");

    $('#ctlModal').on('show.bs.modal', function () {
        setTimeout(function(){ 
            $('.product-description-list li:visible:odd').addClass("colored");
            $('.product-description-list li:last-child').removeClass("colored");
        }, 300);
    });

    if ($(window).width() < 768) {
        
        $(".row-product-image").insertAfter(".container-product-description");

        $(".product-description").addClass("hide-info");
        $(".product-name").click(function(){
            $(".product-description").toggleClass("hide-info");
        });
        
     }
   
});
