import {listProducts} from './functions/list-products';
import {sortProductsList} from './functions/sort-products';

var prodListingPage = $(".products-listing");

if(prodListingPage.length > 0){
    var checkedType = [],
        checkedTreatment = [],
        checkedColor = [],
        checkedNew = [];

    var declarationsLang = "EN";

    $(".declarations-lang-select .btn-grey").on('click', function() {
        $(".declarations-lang-select .btn-grey").removeClass("active");
        $(this).addClass("active");
        declarationsLang = $(this).data("declarations-lang");
    });

    $("#filter").click(function(){
        $(".loader").addClass("loading");
        var selectedFilters = $(".selected-filters"),
            productsContainer = $('.listing');

        //clear all products in .listing container
        productsContainer.empty();
        selectedFilters.empty();

        
        //remove duplicate values in lists
        checkedType = checkedType.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        });
        checkedTreatment = checkedTreatment.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        });
        checkedColor = checkedColor.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        });
        checkedNew = checkedNew.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        });
             
        
        checkedType = [];
        checkedTreatment = [];
        checkedColor = [];
        checkedNew = [0, 1];

        //get checked type
        $(".check-type:checkbox:checked").each(function(index) {
            checkedType.push($(this).attr("name"));
            selectedFilters.append('<button class="btn btn-filter-tag" data-tag="type">'+$(this).attr("name")+'<span> ✕ </span></button>');
        });
        //get checked treatment
        $(".check-treatment:checkbox:checked").each(function() {
            checkedTreatment.push($(this).attr("name"));
            selectedFilters.append('<button class="btn btn-filter-tag" data-tag="treatment">'+$(this).attr("name")+'<span> ✕ </span></button>');
        });
        //get checked color
        $(".check-color:checkbox:checked").each(function() {
            checkedColor.push($(this).attr("name"));
            selectedFilters.append('<button class="btn btn-filter-tag" data-tag="color">'+$(this).attr("name")+'<span> ✕ </span></button>');
        });
        //get checked color
        $(".check-new:checkbox:checked").each(function() {
            checkedNew = checkedNew.filter(item => item !== 0);
            selectedFilters.append('<button class="btn btn-filter-tag" data-tag="new">'+$(this).attr("name")+'<span> ✕ </span></button>');
        });

        //TODO: u bazi dodati tip za null vrijednost i maknuti razmake nakon riječi i onda u array dodati i "null" ili kako već nazoveš praznu vrijednost
        //check if arrays are empty (nothing selected in filter), if yes, select all 
        if (typeof checkedType == 'undefined' || checkedType.length == 0) {
            checkedType = ['Microfiber', 'Artificial leather', 'Woven fabric', 'Artificial fur', 'Deco', 'Chenille', 'Rough woven fabric', 'Outdoor artificial leather', 'Outdoor woven fabric', 'Polypropylen', 'Recycled PES', '', ' ',];
        }
        if (typeof checkedTreatment == 'undefined' || checkedTreatment.length == 0) {
            checkedTreatment = ['Water repellent','Fire retardant','Natural fiber','Stain insensitive','Scratch insensitive','Outdoor superior','Outdoor Junior','Proclean','Printed', 'Quilted', 'Oeko Tex', 'Oeko-tex', 'OEKO-TEX','', ' ',]
        }
        if (typeof checkedColor == 'undefined' || checkedColor.length == 0) {
            checkedColor = ['Black', 'White', 'Grey', 'Brown', 'Green', 'Blue', 'Pink', 'Purple', 'Red', 'Yellow', 'Orange', 'Beige', 'Multicolor','null','0','', ' ',];
        }    
       /*  if (typeof checkedNew == 'undefined' || checkedNew.length == 0 || checkedNew == [0]) {
            console.log(checkedNew);
            checkedNew = [1, 0, 'null', '0', '1', '', ' ',];
        }     */
        checkedType = checkedType.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        });
        checkedTreatment = checkedTreatment.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        });
        checkedColor = checkedColor.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        });
        checkedNew = checkedNew.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        });
        console.log("Filter:")
        console.log(checkedType);
        console.log(checkedTreatment);
        console.log(checkedColor);
        console.log(checkedNew);
        
    $.getJSON("api/product/filter.php?fabric_type="+checkedType+"&fabric_treatment="+checkedTreatment+"&fabric_color="+checkedColor+"&fabric_new="+checkedNew+"", function(data){
        
        if (data.records === undefined) {
            console.log("No products found");
            $(".no-products-found").addClass("show");
        }
        else{
            $(".no-products-found").removeClass("show");
            $(data.records).each(function( index ) {
                //var product = data.records[index]; //single product
                listProducts(data, index, declarationsLang);
            });
        }
        

    }).done(function() {
        //remove tags and checked inputs
        $(".btn-filter-tag span").click(function(){

            var checkedValue = this.parentElement.childNodes[0].nodeValue;
            //console.log(checkedValue);

            var filterType = $(this).parent(".btn-filter-tag").attr("data-tag");

            switch(filterType) {
                case "type":
                    console.log("type: "+checkedValue);
                    checkedType.splice($.inArray(checkedValue, checkedType), 1 );
                    $('.check-type[name="'+checkedValue+'"]').prop('checked',false);
                    if (typeof checkedType == 'undefined' || checkedType.length == 0) {
                        checkedType = ['Microfiber', 'Artificial leather', 'Woven fabric', 'Artificial fur', 'Deco', 'Chenille', 'Rough woven fabric', 'Outdoor artificial leather', 'Outdoor woven fabric', 'Polypropylen', 'Recycled PES', 'Leather'];
                    }
                  break;
                case "treatment":
                    console.log("treatment: "+checkedValue);
                    checkedTreatment.splice($.inArray(checkedValue, checkedTreatment), 1 );
                    $('.check-treatment[name="'+checkedValue+'"]').prop('checked',false);

                  break;
                case "color":
                    console.log("color: "+checkedValue);
                    checkedColor.splice($.inArray(checkedValue, checkedColor), 1 );
                    $('.check-color[name="'+checkedValue+'"]').prop('checked',false);

                  break;
                case "new":
                    console.log("new: "+checkedValue);
                    checkedNew = [0, 1]
                    $('.check-new[name="New"]').prop('checked',false);

                  break;
                default:
                  // code block
              }
           
            //remove tag
            $(this).parent(".btn-filter-tag").remove();

        });

        setTimeout(function(){ 
            $(".loader").removeClass("loading"); 
        }, 
        500);
        
        //Clear filter and list all products
        $("#clearFilter").click(function(){

            //remove all checkboxes
            $('input[type="search"]').val('');
            $(".listing .product").filter(function() {
                $(this).toggle($(this).find(".product-name").text().toLowerCase().indexOf('') > -1)
            });
            $('input[type=checkbox]').removeAttr('checked');
            $('.check-type').prop('checked',false);
            $('.check-treatment').prop('checked',false);
            $('.check-color').prop('checked',false);
            //remove all filter tags
            $(".selected-filters").empty();

            //empty the checked lists
            checkedType = [],
            checkedTreatment = [],
            checkedColor = [];

            $('.listing').empty();


            /* $.getJSON("api/product/read.php", function(data){
                $(data.records).each(function( index ) {
                    listProducts(data.records[index]);
                });
            }); */
        });
    });


});


$( document ).ajaxComplete(function() {
    sortProductsList();
    $(".no-color-match" ).remove();
    if(($(".check-color:checkbox:checked").length > 0)){
        setTimeout(function(){ 
            if ($('.listing').children("div").length){
                $(".no-products-found").removeClass("show");
            }
            else{
                $(".no-products-found").addClass("show"); 
            }
        }, 1000);
    }   
});

}
