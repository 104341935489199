$("#secondMaterial").on("click", function(){
    $("#secondMaterial").css("display", "none");
    $("#thirdMaterial").css("display", "block");
    $(".second-material").css("display", "block");
});
$("#thirdMaterial").on("click", function(){
    $("#thirdMaterial").css("display", "none");
    $("#fourthMaterial").css("display", "block");
    $(".third-material").css("display", "block");
});
$("#fourthMaterial").on("click", function(){
    $("#fourthMaterial").css("display", "none");
    $("#fifthMaterial").css("display", "block");
    $(".fourth-material").css("display", "block");
});
$("#fifthMaterial").on("click", function(){
    $("#fifthMaterial").css("display", "none");
    $(".fifth-material").css("display", "block");
});
$("#interlayerMaterial").on("click", function(){
    $("#interlayerMaterial").css("display", "none");
    $(".interlayer-material").css("display", "block");
});
$("#surfaceFirstMaterial").on("click", function(){
    $("#surfaceFirstMaterial").css("display", "none");
    $("#surfaceSecondMaterial").css("display", "block");
    $(".surface-first-material").css("display", "block");
});
$("#surfaceSecondMaterial").on("click", function(){
    $("#surfaceSecondMaterial").css("display", "none");
    $(".surface-second-material").css("display", "block");
});
$("#raportMaterial").on("click", function(){
    $(".raport").css("display", "block");
});

$("#reviewData").on("click", function(){
    //pass-ati ovdje data objekt sa jsonom od uneenih vrijednosti u formu i prikazati lijepo useru
   /*  var dataObj = {};
    var data = JSON.stringify(dataObj, null, 2); // spacing level = 2
    $(".review-data").text(data); */
});


//validacija forme on keyup
$( "input").keyup(function() {
    var value = $(this).val();
    var input = $(this);
    if(value){
        input.removeClass("error");
    }
});

$(".form-check-input").on('click', function(){
    $(this).parent(".checkboxes").removeClass("error");
});

$( "#fabricColorNumbers").keyup(function() {
    var value = $("#fabricColorNumbers").val();
    var input = $("#fabricColorNumbers");

    if(/\s/g.test(value) || !(/^[0-9.,]+$/g.test(value))){
        input.addClass("error");
    }
    else{
        input.removeClass("error");
    }
});



$("#submit-create-product").on('click', function(){

    //validacija forme na keyup    
    if($("#fabricName").val() < 1){
        $("#fabricName").addClass("error");
    }
    //if has whitespace or if anything but number or comma
    if(/\s/g.test($("#fabricColorNumbers").val()) || !(/^[0-9.,]+$/g.test($("#fabricColorNumbers").val()))){
        $("#fabricColorNumbers").addClass("error");
    }
    if($("#fabricNumbOfColors").val() < 1){
        $("#fabricNumbOfColors").addClass("error");
    }
    if($("#fabricColorNumbers").val() < 1){
        $("#fabricColorNumbers").addClass("error");
    }
    if($("#fabricColorImage").val() < 1){
        $("#fabricColorImage").addClass("error");
    }
    if($("#fabricStrength").val() < 1){
        $("#fabricStrength").addClass("error");
    }
    if(!(/^[0-9,]+$/).test($("#fabricStrength").val())){
        $("#fabricStrength").addClass("error");
    }
    if($("#fabricWeight").val() < 1){
        $("#fabricWeight").addClass("error");
    }
    if($("#fabricWidth").val() < 1){
        $("#fabricWidth").addClass("error");
    }
    if($("#fabricAbrasionTest").val() < 1){
        $("#fabricAbrasionTest").addClass("error");
    }
    if($("#fabricTensileStrengthWarp").val() < 1 ){
        $("#fabricTensileStrengthWarp").addClass("error");
    }
    if($("#fabricTensileStrengthWarpAmount").val() < 1 ){
        $("#fabricTensileStrengthWarpAmount").addClass("error");
    }
    if(!(/^[0-9,]+$/).test($("#fabricTensileStrengthWarpAmount").val())){
        $("#fabricTensileStrengthWarpAmount").addClass("error");
    }
    if($("#fabricTensileStrengthWeft").val() < 1 ){
        $("#fabricTensileStrengthWeft").addClass("error");
    }
    if($("#fabricTensileStrengthWeftAmount").val() < 1 ){
        $("#fabricTensileStrengthWeftAmount").addClass("error");
    }
    if(!(/^[0-9,]+$/).test($("#fabricTensileStrengthWeftAmount").val())){
        $("#fabricTensileStrengthWeftAmount").addClass("error");
    }
    if($("#fabricTearGrowthResistanceWarp").val() < 1 ){
        $("#fabricTearGrowthResistanceWarp").addClass("error");
    }
    if(!(/^[0-9,]+$/).test($("#fabricTearGrowthResistanceWarp").val())){
        $("#fabricTearGrowthResistanceWarp").addClass("error");
    }
    if($("#fabricTearGrowthResistanceWeft").val() < 1 ){
        $("#fabricTearGrowthResistanceWeft").addClass("error");
    }
    if(!(/^[0-9,]+$/).test($("#fabricTearGrowthResistanceWeft").val())){
        $("#fabricTearGrowthResistanceWeft").addClass("error");
    }
    if($("#fabricResistanceSeamSlippageWarp").val() < 1 ){
        $("#fabricResistanceSeamSlippageWarp").addClass("error");
    }
    if(!(/^[0-9,]+$/).test($("#fabricResistanceSeamSlippageWarp").val())){
        $("#fabricResistanceSeamSlippageWarp").addClass("error");
    }
    if($("#fabricResistanceSeamSlippageWeft").val() < 1 ){
        $("#fabricResistanceSeamSlippageWeft").addClass("error");
    }
    if(!(/^[0-9,]+$/).test($("#fabricResistanceSeamSlippageWeft").val())){
        $("#fabricResistanceSeamSlippageWeft").addClass("error");
    }
    if(!($("input[name='fabric-new']:checked").length)){
        $("input[name='fabric-new']").parent(".checkboxes").addClass("error");
    }
    if(!($("input[name='fabric-bally']:checked").length)){
        $("input[name='fabric-bally']").parent(".checkboxes").addClass("error");
    }
    if(!($("input[name='fabric-soil-release']:checked").length)){
        $("input[name='fabric-soil-release']").parent(".checkboxes").addClass("error");
    }
    if(!($("input[name='fabric-cigarette-test']:checked").length)){
        $("input[name='fabric-cigarette-test']").parent(".checkboxes").addClass("error");
    }
    if($("#spamTest").val() != 5){
        $("#spamTest").addClass("error");
    }
    // --- end validacija forme

    //if there are errors...
    if (($("#create-product-form").find('.error').length >= 1)){
        $(".alert-for-errors").addClass("show");
        setTimeout(function(){ 
            $(".alert-for-errors").removeClass("show");
        }, 8000);
        $("html, body").animate({ scrollTop: 0 }, "fast");
    }
    //if there are no errors...
    else if (($("#create-product-form").find('.error').length == false)){
        
        // create data object
        var dataObj = {
            "fabric_name": $("#fabricName").val(), 
            "fabric_type": $("#fabricType").val(),
            "fabric_treatment": $("#fabricTreatment").val(),
            "fabric_treatment2": $("#fabricTreatment2").val(),
            "fabric_treatment3": $("#fabricTreatment3").val(),
            "fabric_treatment_available": $("#fabricTreatmentAvailable").val(),
            "fabric_treatment2_available": $("#fabricTreatment2Available").val(),
            "fabric_treatment3_available": $("#fabricTreatment3Available").val(),
            "fabric_special_note": $("#fabricSpecialNote").val(),
            "fabric_new": $('input[name="fabric-new"]:checked').val(),
            "fabric_number_of_colors": $("#fabricNumbOfColors").val(),
            "fabric_color_number": $("#fabricColorNumbers").val(),
            "fabric_color_img" : $("#fabricColorImage").val(),
            "fabric_material1_amount" : $("#fabricMaterial1Amount").val(),
            "fabric_material1" : $("#fabricMaterial1").val(),
            "fabric_material2_amount" : $("#fabricMaterial2Amount").val(),
            "fabric_material2" : $("#fabricMaterial2").val(),
            "fabric_material3_amount" : $("#fabricMaterial3Amount").val(),
            "fabric_material3" : $("#fabricMaterial3").val(),
            "fabric_material4_amount" : $("#fabricMaterial4Amount").val(),
            "fabric_material4": $("#fabricMaterial4").val(),
            "fabric_material5_amount" : $("#fabricMaterial5Amount").val(),
            "fabric_material5" : $("#fabricMaterial5").val(),
            "fabric_interlayer_amount": $("#fabricInterlayerAmount").val(),
            "fabric_interlayer_material" : $("#fabricInterlayer").val(),
            "fabric_surface1_amount" : $("#fabricSurface1Amount").val(),
            "fabric_surface1_material": $("#fabricSurface1").val(),
            "fabric_surface2_amount" : $("#fabricSurface2Amount").val(),
            "fabric_surface2_material" : $("#fabricSurface2").val(),

            "fabric_strength" : $("#fabricStrength").val(),
            "fabric_weight": $("#fabricWeight").val(),
            "fabric_width": $("#fabricWidth").val(),

            "fabric_tensile_strength_warp" : $("#fabricTensileStrengthWarp").val(),
            "fabric_tensile_strength_warp_amount" : $("#fabricTensileStrengthWarpAmount").val(),
            "fabric_tensile_strength_weft" : $("#fabricTensileStrengthWeft").val(),
            "fabric_tensile_strength_weft_amount": $("#fabricTensileStrengthWeftAmount").val(),
            
            "fabric_tear_growth_resistance_warp" : $("#fabricTearGrowthResistanceWarp").val(),
            "fabric_tear_growth_resistance_weft" : $("#fabricTearGrowthResistanceWeft").val(),
            
            "fabric_resistance_to_seam_slippage_warp" : $("#fabricResistanceSeamSlippageWarp").val(),
            "fabric_resistance_to_seam_slippage_weft": $("#fabricResistanceSeamSlippageWeft").val(),
            
            "fabric_abrasion_test": $("#fabricAbrasionTest").val(),
            "fabric_pilling_test": $("#fabricPillingTest").val(),
            "fabric_fastness_to_light" : $("#fabricFastnessLight").val(),

            "fabric_fastness_to_rubbing_dry" : $("#fabricFastnessRubbingDry").val(),
            "fabric_fastness_to_rubbing_wet" : $("#fabricFastnessRubbingWet").val(),        
            
            "fabric_cigarette_test": $('input[name="fabric-cigarette-test"]:checked').val(),
            "fabric_soil_release_finish" :  $('input[name="fabric-soil-release"]:checked').val(),
            "fabric_bally_test" :  $('input[name="fabric-bally"]:checked').val(),
            "fabric_bally_test_after_hydrolyses":  $('input[name="fabric-bally-hydro"]:checked').val(),
            
            "fabric_wash" : $("#fabricWash").val(),
            "fabric_chlorinate": $("#fabricChlorinate").val(),
            "fabric_tumbler_dry": $("#fabricTumblerDry").val(),
            "fabric_iron" : $("#fabricIron").val(),
            "fabric_dry_cleaning" : $("#fabricDryCleaning").val(),
            "fabric_raport_width" : $("#fabricRaportWidth").val(),
            "fabric_raport_height" : $("#fabricRaportHeight").val()
        };
        

        console.log(dataObj);

        var data = JSON.stringify( dataObj );
        
        // send form data to the server
       $.ajax({
            url: 'api/product/create.php', 
            contentType: 'application/json',
            type : "POST",
            dataType : 'json', 
            data : data ,
            success : function(result) {
                console.log(result);
                //show success message, scroll to top and reset form data
                $(".alert-success").addClass("show");
                setTimeout(function(){$(".alert-success").removeClass("show");}, 10000);
                $("html, body").animate({ scrollTop: 0 }, "fast");
                $('#create-product-form')[0].reset();
                return false;
            },
            error: function(xhr, resp, text) {
                console.log(xhr, resp, text);
                //fail poruka
                $(".alert-for-fail").addClass("show");
                setTimeout(function(){ 
                    $(".alert-for-fail").removeClass("show");
                }, 10000);
                $("html, body").animate({ scrollTop: 0 }, "fast");
                return false;
            }
        });   
    }
});