import headerBckg from '../img/bckg.jpg';
import contacBbckg from '../img/bckg-contact.jpg';
import slider1 from '../img/slider/01.jpg';
import slider2 from '../img/slider/02.jpg';
import slider3 from '../img/slider/03.jpg';
//import video1 from '../videos/bckg-video.mp4';
/* import video2 from '../videos/textum-promo-video.mp4';
import video3 from '../videos/heim19_video.mp4';
import video4 from '../videos/como19_video.mp4';
import video5 from '../videos/textum19_video.mp4'; */


/* console.log(video1);
console.log(video2);
console.log(video3);
console.log(video4); */
//console.log(video5);
/* function importAll(r) {
    return r.keys().map(r);
  }
const images = importAll(require.context('../img/icons', false, /\.(png|jpe?g|svg)$/));
 */