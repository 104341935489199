console.log("cookie skripta je pozvana");
document.addEventListener('DOMContentLoaded', () => {

  console.log("DOM je loadan inicijaliziraj cookie...");
  console.log(cookieconsent);
  var userLang;
  var lang;
  var langFromStorage = localStorage.getItem('language');

  userLang = navigator.language || navigator.userLanguage; 
  userLang = userLang.slice(0,2);

  if(langFromStorage){
    lang = langFromStorage;
  }
  //ako user nije selektirao jezik, uzmi defaultni jezik od userovog browsera
  else if(!langFromStorage && userLang){
      lang = userLang;
  }
  //ako nije selektirao jezik niti nema default u browseru, uzmi default iz html taga (en)
  else if(!langFromStorage && !userLang){
      lang = $("html").attr("lang");
  }
  //ako ne postoji defaultni jezik definiran u html tagu, postavi ga na en
  else if (lang == undefined || lang == ""){
      lang = "en";
  }

  if(lang == "en"){
    cookieMsg = "This website only uses essential cookies that enable this website to work. We won't collect any data for marketing purposes.";
    dissmissMsg = "Got it!";
    linkMsg = "Learn more";
  }
  if(lang == "de"){
    cookieMsg = "Diese Webseite verwendet nur notwendige Cookies, die das Funktionieren dieser Webseite ermöglichen. Wir sammeln keine Daten für Marketingzwecke.";
    dissmissMsg = "Akzeptieren!";
    linkMsg = "Mehr Info";
  }
  if(lang == "hr"){
    cookieMsg = "Ova web stranica koristi samo ključne kolačiće koji omogućuju rad ove web stranice. Nećemo prikupljati nikakve podatke u marketinške svrhe.";
    dissmissMsg = "Razumijem!";
    linkMsg = "Saznaj više";
  }

    cookieconsent.initialise({
      container: document.getElementById("site-wrapper"),
      palette:{
       popup: {background: "#b5152b"},
       button: {background: "#ffffff"},
      },
      revokable:true,
      /*onStatusChange: function(status) {
          console.log(this.hasConsented() ?
           'enable cookies' : 'disable cookies');
      }, */
      position: "bottom-right",
      content: {
          "message": cookieMsg,
          "dismiss": dissmissMsg,
          "link": linkMsg,
          "href": "https://www.textum-stoffe.com/privacy-policy.html"
      },
      law: {
       regionalLaw: false,
      },
      location: true,
    });
  });