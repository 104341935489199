
import {listProducts} from './functions/list-products';
import {sortProductsList} from './functions/sort-products';

var prodListingPage = $(".products-listing");

if(prodListingPage.length > 0){
   $(".loader").addClass("loading");

    $.getJSON("api/product/read.php", function(data){

    }).done(function(data){
        $(data.records).each(function( index ) {
            listProducts(data, index, declarationsLang);
        });
        
        $(".btn-filter-tag span").click(function(){

            let checkedValue = this.parentElement.childNodes[0].nodeValue;
            $('input[name="'+checkedValue+'"]').removeAttr('checked');
            $(this).parent(".btn-filter-tag").remove();

        });

        setTimeout(function(){ 
            $(".loader").removeClass("loading"); 
        }, 
        500);
    });
}


var declarationsLang = "EN";

$(".declarations-lang-select .btn-grey").on('click', function() {
    $(".declarations-lang-select .btn-grey").removeClass("active");
    $(this).addClass("active");
    declarationsLang = $(this).data("declarations-lang");

    $(".loader").addClass("loading");
    var selectedFilters = $(".selected-filters"),
        productsContainer = $('.listing');

    //clear all products in .listing container
    productsContainer.empty();
    selectedFilters.empty();

    $(".loader").addClass("loading");

    $.getJSON("api/product/read.php", function(data){

    }).done(function(data){
        $(data.records).each(function( index ) {
            listProducts(data, index, declarationsLang);
        });
        
        $(".btn-filter-tag span").click(function(){

            let checkedValue = this.parentElement.childNodes[0].nodeValue;
            $('input[name="'+checkedValue+'"]').removeAttr('checked');
            $(this).parent(".btn-filter-tag").remove();

        });

        setTimeout(function(){ 
            $(".loader").removeClass("loading"); 
        }, 
        500);
    });
});

$( document ).ajaxComplete(function() {
    sortProductsList();
    $('[data-toggle="tooltip"]').tooltip();
});




