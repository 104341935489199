
import {imageExists} from './functions/helpers';
//import QRCode from 'qrcode'

//import analyze from 'rgbaster'
var prodDetailPage = $(".products-detail");

if(prodDetailPage.length > 0){

    var url_string = window.location.href;
    var url = new URL(url_string);
    var url_fabric_name = url.searchParams.get("fabric_name");
    var lang = localStorage.getItem('language');

    //find each occurrence of a lower case character followed by an upper case character, and insert a space between them:
    //example Avon, Jade Ibisco, Epic FB - this is how items shoud be named in the database
    var selectedFabric = url_fabric_name.replace(/([a-z])([A-Z])/g, '$1 $2') ;
    var selectedColor = url.searchParams.get("fabric_color");

    $.getJSON("api/product/read-one-product.php?fabric_name="+selectedFabric, function(data){
        //console.log(data.records[0]);
        var product = data.records[0];
        
        //handle how materials display
        var material1 = product.fabric_material1_amount +"% "+ product.fabric_material1;
        var material2 = ", " + product.fabric_material2_amount +"% "+ product.fabric_material2;
        var material3 = ", " + product.fabric_material3_amount +"% "+ product.fabric_material3;
        var material4 = ", " + product.fabric_material4_amount +"% "+ product.fabric_material4;
        var material5 = ", " + product.fabric_material5_amount +"% "+ product.fabric_material5;
            if(material1.includes('-999')){material1 = "";}
            if(material2.includes('-999')){material2 = "";}
            if(material3.includes('-999')){material3 = "";}
            if(material4.includes('-999')){material4 = "";}
            if(material5.includes('-999')){material5 = "";}

        var surfaceMaterial1 = product.fabric_surface1_amount +"% "+ product.fabric_surface1_material;
        var surfaceMaterial2 = ", " + product.fabric_surface2_amount +"% "+ product.fabric_surface2_material;
            if(surfaceMaterial1.includes('-999')){surfaceMaterial1 = ""; $(".surface").parent("li").hide();}
            if(surfaceMaterial2.includes('-999')){surfaceMaterial2 = "";}

        //handle how wash instructions display
        var washIcon = getWashing(product.fabric_wash, washIcon);
        var chlorIcon = getChlorinate(product.fabric_chlorinate, chlorIcon);
        var dryIcon = getDrying(product.fabric_tumbler_dry, dryIcon);
        var ironIcon = getIroning(product.fabric_iron, ironIcon);
        var drycleanIcon = getDrycleaning(product.fabric_dry_cleaning, drycleanIcon);

        var treatmentClass = setTreatmentType(product.fabric_treatment, treatmentClass);
        var treatmentClass2 = setTreatmentType(product.fabric_treatment2, treatmentClass);
        var treatmentClass3 = setTreatmentType(product.fabric_treatment3, treatmentClass);

        var treatmentMsg = setTreatmentMsg(product.fabric_treatment, product.fabric_treatment_available, lang);
        var treatmentMsg2 = setTreatmentMsg(product.fabric_treatment2, product.fabric_treatment2_available, lang);
        var treatmentMsg3 = setTreatmentMsg(product.fabric_treatment3, product.fabric_treatment3_available, lang);

        var newClass = checkifNewFabric(product.fabric_new, newClass);

        var colors = product.fabric_color_number.split(",");   
       
        var fabricImgPath;

        var cigaretteTest = product.fabric_cigarette_test;
            if(cigaretteTest.includes('-999')){cigaretteTest = "Failed";}
            else if(cigaretteTest == "1"){cigaretteTest = "Passed";}

        var stainReleaseFinish = product.fabric_soil_release_finish;
            if(stainReleaseFinish == "0"){stainReleaseFinish = "on demand";}
            else if(stainReleaseFinish == "1"){stainReleaseFinish = "available";}
            else if(stainReleaseFinish == "2"){stainReleaseFinish = "top coat available as stain protected";}
            else if(stainReleaseFinish == "3"){stainReleaseFinish = "stain resistant";}
            else if(stainReleaseFinish == "4"){stainReleaseFinish = "to aqueous media good stain protection available, against fatty media no stain protection available";}

        var ballyTest = product.fabric_bally_test;
            if(ballyTest.includes('-999')){ballyTest = "N/A";}
            else if(ballyTest == "-1"){ballyTest = "OK";}
            else if(ballyTest == "-2"){ballyTest = "NOT OK";}

        var raportHeight = product.fabric_raport_height;
        var raportWidth = product.fabric_raport_width;
        
        if(raportHeight == '-999'){
            raportHeight = "N/A"; 
            $(".raport-width").parent("li").hide();
        }
        if(raportWidth == '-999'){
            raportWidth = "N/A";
        }

        $(".treatment-logo-link").html("<a target='_blank' href='./products-treatment.html?fabric_treatment="+product.fabric_treatment+"'><img src='./img/treatment/treatment-logo/"+treatmentClass+".svg' alt='treatment-logo'></a>");
        if(!(product.fabric_treatment2 == "")){
            $(".treatment-logo-link").append("<a target='_blank' href='./products-treatment.html?fabric_treatment="+product.fabric_treatment2+"'><img src='./img/treatment/treatment-logo/"+treatmentClass2+".svg' alt='treatment-logo'></a>");
        }
        if(!(product.fabric_treatment3 == "")){
            console.log("aslasasasas")
            console.log(treatmentClass3)
            $(".treatment-logo-link").append("<a target='_blank' href='./products-treatment.html?fabric_treatment="+product.fabric_treatment3+"'><img src='./img/treatment/treatment-logo/"+treatmentClass3+".svg' alt='treatment-logo'></a>");
        }
        if(treatmentClass == ""){$(".treatment-logo-link, .treatment, .treatment-label").addClass("hide");}

        $(".product-description .product-name").prepend(product.fabric_name);

        if(product.fabric_treatment == "Quilted"){
            $(".product-description .product-name .quilted-base-fabric").removeClass("hide");
            $(".product-description .product-name .quilted-base-fabric a").append(product.fabric_special_note);
            $(".product-description .product-name .quilted-base-fabric a").attr("href", "products-detail.html?fabric_name="+product.fabric_special_note+"");
        }
        $(".modal-header .product-name").text(product.fabric_name);
        $(".product-description .color-label").text(selectedColor);
        $(".composition").text(material1 + material2 + material3 + material4 + material5);
        $(".surface").text(surfaceMaterial1 + surfaceMaterial2);
        $(".width").text(product.fabric_width + " cm ± 2%");
        $(".weight").text(product.fabric_weight + " g/m2 ± 5%");
        $(".strength").text(product.fabric_strength + " mm ± 2%");
        $(".treatment").html("<a target='_blank' href='./products-treatment.html?fabric_treatment="+product.fabric_treatment+"'><img src='./img/treatment/treatment-logo/"+treatmentClass+".svg' alt='treatment-logo'>"+product.fabric_treatment+"</a>"+treatmentMsg);
        if(!(product.fabric_treatment2 == "")){
            $(".treatment").append("<a target='_blank' href='./products-treatment.html?fabric_treatment="+product.fabric_treatment2+"'><img src='./img/treatment/treatment-logo/"+treatmentClass2+".svg' alt='treatment-logo'>"+product.fabric_treatment2+"</a>"+treatmentMsg2);
        }
        if(!(product.fabric_treatment3 == "")){
            $(".treatment").append("<a target='_blank' href='./products-treatment.html?fabric_treatment="+product.fabric_treatment3+"'><img src='./img/treatment/treatment-logo/"+treatmentClass3+".svg' alt='treatment-logo'>"+product.fabric_treatment3+"</a>"+treatmentMsg3);
        }
        $(".category").text(product.fabric_type);
        $(".download-pdf").attr("href", 'api/getPDF.php?material='+product.fabric_name);
        
        $(".tensile-strength-warp").text(product.fabric_tensile_strength_warp + "N / " + product.fabric_tensile_strength_warp_amount + "%");
        $(".tensile-strength-weft").text(product.fabric_tensile_strength_weft + "N / " + product.fabric_tensile_strength_weft_amount + "%");
        $(".tear-growth-warp").text(product.fabric_tear_growth_resistance_warp + "N");
        $(".tear-growth-weft").text(product.fabric_tear_growth_resistance_weft + "N");
        $(".resistance-slippage-warp").text(product.fabric_resistance_to_seam_slippage_warp + "mm");
        $(".resistance-slippage-weft").text(product.fabric_resistance_to_seam_slippage_weft + "mm");
        $(".abrasion-resistance").text(product.fabric_abrasion_test + " rounds");
        $(".pilling-test").text(product.fabric_pilling_test);
        $(".fastness-to-light").text(product.fabric_fastness_to_light);
        $(".fastness-rubbing-dry").text(product.fabric_fastness_to_rubbing_dry);
        $(".fastness-rubbing-wet").text(product.fabric_fastness_to_rubbing_wet);
        $(".cigarette-test").text(cigaretteTest);
        $(".stain-resistant-finish").text(stainReleaseFinish);
        $(".bally-test").text(ballyTest);
        $(".raport-width").text(raportWidth + "cm");
        $(".raport-height").text(raportHeight + "cm");

        $(".care").append(
            '<img src="'+washIcon+'" alt="wash-icon" class="symbol wash">'+
            '<img src="'+chlorIcon+'" alt="chlor-icon" class="symbol chlor">'+
            '<img src="'+dryIcon+'" alt="dry-icon" class="symbol dry">'+
            '<img src="'+ironIcon+'" alt="iron-icon" class="symbol iron">'+
            '<img src="'+drycleanIcon+'" alt="dryclean-icon" class="symbol dryclean">'
        );

        
        
        var imageUrl = './img/fabrics/'+product.fabric_name.replace(/\s/g, '')+'_'+selectedColor+'_pl.jpg';
        imageExists(imageUrl, function(exists) {
            //img gallery
            $(".product-image").find("a").attr("href", './img/fabrics/'+product.fabric_name.replace(/\s/g, '')+'_'+selectedColor+'_pl.jpg');
            $(".product-image").find("img").attr("src", './img/fabrics/'+product.fabric_name.replace(/\s/g, '')+'_'+selectedColor+'_pl.jpg');
            if(exists == false){
                $(".product-image").find("a").attr("href", './img/fabrics/_no-image.jpg');
                $(".product-image").find("img").attr("src", './img/fabrics/_no-image.jpg');
            }
        });
        
        $(colors).each(function( index ) {
            var colorNumber = colors[index].replace(/\//g, '_');
            
            fabricImgPath = './img/fabrics/'+product.fabric_name.replace(/\s/g, '')+'_'+colorNumber+'_pl.jpg';
            $(".listing").append(
                '<div class="product">'+
                '<a href="./products-detail.html?fabric_name='+product.fabric_name.replace(/\s/g, '')+'&fabric_color='+colorNumber+'">'+
                        '<span class="'+newClass+'">New</span>'+
                        '<span class="product-label '+treatmentClass+'">'+product.fabric_treatment+'</span>'+
                        '<span class="product-label product-label--second '+treatmentClass2+'">'+product.fabric_treatment2+'</span>'+
                        '<span class="product-label product-label--third '+treatmentClass3+'">'+product.fabric_treatment3+'</span>'+ 
                        '<span class="product-thumb"><img src=./img/fabrics/'+product.fabric_name.replace(/\s/g, '')+'_'+colorNumber+'_pl.jpg > </span> '+
                        '<span class="product-name">'+product.fabric_name +" "+ colors[index]+'</span>'+
                    '</a>'+
                    '<span class="product-type" data-lang="'+product.fabric_type+'">'+product.fabric_type +'</span>'+
                '</div>'
            );
            //get img path ne radi na live web-u zato što je u htaccesu napisan rule koji redirecta sve nepostojeće urlove na homepage
            //zato ovaj get ne radi na live-u dok lokalno radi
            //i zato je dolje na "done" napravljeno da nakon load-a pregleda visinu product-thumb slike, i ako je manja od 20px, doda no-image
            //nije elegantno rješenje, al radi
             /* $.get(fabricImgPath)
                 .done(function() { 
                     $(".listing").append(
                        '<div class="product">'+
                        '<a href="./products-detail.html?fabric_name='+product.fabric_name.replace(/\s/g, '')+'&fabric_color='+colorNumber+'">'+
                                '<span class="'+newClass+'">New</span>'+
                                '<span class="product-label '+treatmentClass+'">'+product.fabric_treatment+'</span>'+
                                '<span class="product-label product-label--second '+treatmentClass2+'">'+product.fabric_treatment2+'</span>'+
                                '<span class="product-thumb"><img src=./img/fabrics/'+product.fabric_name.replace(/\s/g, '')+'_'+colorNumber+'_pl.jpg > </span> '+
                                '<span class="product-name">'+product.fabric_name +" "+ colors[index]+'</span>'+
                            '</a>'+
                            '<span class="product-type" data-lang="'+product.fabric_type+'">'+product.fabric_type +'</span>'+
                        '</div>'
                    );
                 })
                 .fail(function(){
                     console.log("path nije ok!");
                     fabricImgPath = './img/fabrics/_no-image.jpg';
                     $(".listing").append(
                        '<div class="product">'+
                            '<a href="./products-detail.html?fabric_name='+product.fabric_name.replace(/\s/g, '')+'&fabric_color='+colorNumber+'">'+
                                '<span class="'+newClass+'">New</span>'+
                                '<span class="product-label '+treatmentClass+'">'+product.fabric_treatment+'</span>'+
                                '<span class="product-label product-label--second '+treatmentClass2+'">'+product.fabric_treatment2+'</span>'+                                
                                '<span class="product-thumb"><img src="'+fabricImgPath+'" alt="fabric-thumb"> </span> '+
                                '<span class="product-name">'+product.fabric_name +" "+ colors[index]+'</span>'+
                            '</a>'+
                            '<span class="product-type" data-lang="'+product.fabric_type+'">'+product.fabric_type +'</span>'+
                        '</div>'
                    );
                 }); */
                 
         });
         

    }).done(function() {
        //mijenjanje boje label-a u boju slike koja je dominantna
       /*  $('.product-image img').on('load', function() {
           var src = $(this).attr("src");
           (async () => {
            const result = await analyze(src) // also supports base64 encoded image strings
            $(".color-label").css("background-color", result[0].color);
            //console.log(`The dominant color is ${result[0].color} with ${result[0].count} occurrence(s)`)
            // => The  dominant color is rgb(0,0,255) with 2 occurrence(s)
           // console.log(`The secondary color is ${result[1].color} with ${result[1].count} occurrence(s)`)
            // => The  secondary color is rgb(255,0,0) with 1 occurrence(s)
            // var fabric_name = window.location.hash.substr(1);
        })(); 
        });*/
        
    });

    $(window).on("load", function() {   
        setTimeout(function(){ 
            $(".product-thumb img").each(function( index ) {
                if($(this).height() < 20){
                    $(this).attr("src", './img/fabrics/thumbs/_no-image.jpg');
                }
            });
           
        }, 5000);
       
        if($(".product-info").height() > 600){
            $(".product-description").css("height", "calc(100% + 50px)");
        }
        
        var lang = localStorage.getItem('language');
        //Ako je Monteria, pokaži poruku o bojama (napraviti i za Campos), TODO: automatizirati
        if($("h1.product-name")[0].innerText == "MONTERIA"){
            var colorsMsg ="";
            if(lang == "de"){
                colorsMsg = "* Farben auf Lager: 5497, 5501, 5507, 9093, 9534. Andere Farben verfügbar für Bestellungen ab 500 m (FR-Norm EN ISO 1021 1 + 2)";
            }
            if(lang == "hr"){
                colorsMsg = "* Boje na lageru: 5497, 5501, 5507, 9093, 9534. Ostale boje dostupne za narudžbe od 500m (FR standard EN ISO 1021 1 + 2)";
            }
            else{
                colorsMsg = "* Colors on stock: 5497, 5501, 5507, 9093, 9534. Other colors available for orders of 500m (FR standard EN ISO 1021 1+2)";
            }
            $(".other-colors h3").after("<p style='margin-left:15px;'>"+colorsMsg+"</p>");

        }

        //uncomment to show qr code
            /* var url = window.location.href;

            QRCode.toDataURL(url)
            .then(url => {
                console.log(url);
                $(".product-qr").append("<img src="+url+">");
            })
            .catch(err => {
                console.error(err)
            }) */

        });
        
    function setTreatmentMsg(treatment, treatmentAvailability, lang){
        var infoMsg = "";
        if(treatmentAvailability == "available-to-order"){
            if(treatment == "Water repellent" || treatment == "Stain insensitive"){
                if(lang == "de"){
                    infoMsg = "* Nachbehandlung in Deutschland, Stain insensitive and Water repellent in 2-3 Wochen verfügbar";
                }
                if(lang == "hr"){
                    infoMsg = "* Naknadni tretman u Njemačkoj, Stain insensitive and Water repellent dostupni za 2-3 tjedna";
                }
                else{
                    infoMsg = "* After treatment in Germany, Stain protection and Water repellent treatment available in 2-3 weeks";
                }
            }
            else if(treatment == "Fire retardant"){
                if(lang == "de"){
                    infoMsg = "* Nachbehandlung in Deutschland, FR-Normen EN ISO 1021 1 + 2 und Crib5 in 2-3 Wochen verfügbar";
                }
                else if(lang == "hr"){
                    infoMsg = "* Naknadni tretman u Njemačkoj, FR standardi EN ISO 1021 1 + 2 i Crib5 dostupni za 2-3 tjedna ";
                }
                else {
                    infoMsg = "* After treatment in Germany, FR standards EN ISO 1021 1+2 and Crib5 available in 2-3 weeks";
                }
            }
            infoMsg = "<span class='treatment-info-msg'>"+infoMsg+"</span>";
        }
        return infoMsg;
    } 
    function setTreatmentType(treatment, treatmentClass){
        switch (treatment) { 
            case 'Fire retardant': 
                treatmentClass = "fire-retardant";
                break;
            case 'Water repellent': 
                treatmentClass = "water-repellent";
                break;
            case 'Natural fiber': 
                treatmentClass = "natural-fiber";
                break;		
            case 'Outdoor Superior': 
                treatmentClass = "outdoor-superior";
                break;
            case 'Outdoor Junior': 
                treatmentClass = "outdoor-junior";
                break;
            case 'Stain insensitive': 
                treatmentClass = "stain-insensitive";
                break;
            case 'Scratch insensitive': 
                treatmentClass = "scratch-insensitive";
                break;
            case 'Quilted': 
                treatmentClass = "quilted";
                break;
            case 'Proclean': 
                treatmentClass = "proclean";
                break;
            case 'Printed': 
                treatmentClass = "printed";
                break;
            case 'OEKO-TEX': 
                treatmentClass = "oeko-tex";
                break;
            default:
                treatmentClass = "";
        }
        return treatmentClass;
    }
    function checkifNewFabric(fabricNew, newClass){
        if(fabricNew == 1){
            newClass = "product-new";
        }
        else{
            newClass="product-old";
        }
        return newClass;
    }
    //washing symbol icons
    function getWashing(washing, washIcon){
        switch (washing) { 
            case "0":
                washIcon = './img/wash_symbols/wash_no.jpg';
                break;
            case "1": 
                washIcon = './img/wash_symbols/wash_no.jpg';
                break;
            case "2": 
                washIcon = './img/wash_symbols/wash_hand.jpg';
                break;
            case "3": 
                washIcon = './img/wash_symbols/wash_30.jpg';
                break;		
            case "4": 
                washIcon = './img/wash_symbols/wash_40.jpg';
                break;
            case "5": 
                washIcon = './img/wash_symbols/wash_60.jpg';
                break;
            case "6": 
                washIcon = './img/wash_symbols/wash_90.jpg';
                break;
            default:
                washIcon = "";
        }
        return washIcon;
    }
    function getChlorinate(chlorinate, chlorIcon){
        switch (chlorinate) { 
            case "0":
                chlorIcon = './img/wash_symbols/chlor_no.jpg';
                break;
            case "1": 
                chlorIcon = './img/wash_symbols/chlor_no.jpg';
                break;
            case "2": 
                chlorIcon = './img/wash_symbols/chlor_yes.jpg';
                break;
            default:
                chlorIcon = "";
        }
        return chlorIcon;
    }
    function getDrying(drying, dryIcon){
        switch (drying) { 
            case "0": 
                dryIcon = './img/wash_symbols/dry_no.jpg';
                break;
            case "1": 
                dryIcon = './img/wash_symbols/dry_no.jpg';
                break;
            case "2": 
                dryIcon = './img/wash_symbols/dry_low.jpg';
                break;
            case "3": 
                dryIcon = './img/wash_symbols/dry_mid.jpg';
                break;		
            default:
                dryIcon = "";
        }
        return dryIcon;
    }
    function getIroning(ironing, ironIcon){
        switch (ironing) { 
            case "0":
                ironIcon = './img/wash_symbols/iron_no.jpg';
                break;
            case "1": 
                ironIcon = './img/wash_symbols/iron_no.jpg';
                break;
            case "2": 
                ironIcon = './img/wash_symbols/iron_weak.jpg';
                break;
            case "3": 
                ironIcon = './img/wash_symbols/iron_mid.jpg';
                break;
            case "4": 
                ironIcon = './img/wash_symbols/iron_high.jpg';
                break;		
            default:
                ironIcon = "";
        }
        return ironIcon;
    }
    function getDrycleaning(drycleaning, drycleanIcon){
        switch (drycleaning) { 
            case "0":
                drycleanIcon = './img/wash_symbols/dryclean_no.jpg';
                break;
            case "1": 
                drycleanIcon = './img/wash_symbols/dryclean_no.jpg';
                break;
            case "2": 
                drycleanIcon = './img/wash_symbols/dryclean_perchlor.jpg';
                break;
            case "3": 
                drycleanIcon = './img/wash_symbols/dryclean_perchlor_gentle.jpg';
                break;		
            case "4": 
                drycleanIcon = './img/wash_symbols/dryclean_perchlor_very_gentle.jpg';
                break;
            default:
                drycleanIcon = "";
        }
        return drycleanIcon;
    }
}
