import {listProducts} from './functions/list-products';
import {sortProductsList} from './functions/sort-products';

var prodTreatmentPage = $(".products-treatment");

if(prodTreatmentPage.length > 0){

    var url_string = window.location.href;
    var url = new URL(url_string);
    var selectedTreatment = url.searchParams.get("fabric_treatment");

    var checkedType = ['Microfiber', 'Artificial leather', 'Woven fabric', 'Artificial fur', 'Deco', 'Chenille', 'Rough woven fabric', 'Outdoor artificial leather', 'Outdoor woven fabric', 'Polypropylen', 'Recycled PES', 'Leather', '', ' ',];
    var checkedColor = ['Black', 'White', 'Grey', 'Brown', 'Green', 'Blue', 'Pink', 'Purple', 'Red', 'Yellow', 'Orange', 'Beige', 'Multicolor','null','0','', ' ',];    
    var checkedNew = [0, 1];
    var checkedTreatment = [selectedTreatment];

    var productsContainer = $('.listing');
    //clear all products in .listing container
    productsContainer.empty();



    if(selectedTreatment != null){
        getProductsByTreatmentType(checkedType, checkedTreatment);

        $(".treatments-list a").removeClass("active");
        $(".treatment-container").removeClass("active");
        $(".treatment-cover").removeClass("active");

        var treatment = $(".treatments-list li a[name='"+selectedTreatment+"']");
        var dataTreatment = treatment.data("treatment");
        treatment.addClass("active");
        $(".treatment-container[data-treatment='"+dataTreatment+"']").addClass("active");
        $(".treatment-cover[data-treatment='"+dataTreatment+"']").addClass("active");
    }
    else{
        productsContainer.append(
            "<h4>We couldn't list the available products for the selected category.</h4>"
        )
    }

    
    //Treatments Page
    $(".treatments-list a").click(function(){
        var treatment = $(this).data("treatment");
        var selectedTreatment = $(this).attr("name");

        //update the url with parameters of selected fabric treatment
        history.pushState({
            id: 'treatment'
        //}, 'Product Treatment | '+selectedTreatment+'', 'http://localhost:8080/textum-v2/dist/products-treatment.html?fabric_treatment='+selectedTreatment+'');
        }, 'Product Treatment | '+selectedTreatment+'', 'https://www.textum-stoffe.com/products-treatment.html?fabric_treatment='+selectedTreatment+'');
        //lokalna adresa
        //

        $(".treatments-list a").removeClass("active");
        $(".treatment-container").removeClass("active");
        $(".treatment-cover").removeClass("active");

        //handle treatment menu on S screen
        $(".treatments-list").addClass('hide');
        $(".show-treatments").addClass('hide');
        $(".show-treatments span").text("Show treatments");

        $(this).addClass("active");
        $(".treatment-container[data-treatment='"+treatment+"']").addClass("active");
        $(".treatment-cover[data-treatment='"+treatment+"']").addClass("active");
  
        var checkedTreatment = [selectedTreatment];
        productsContainer.empty();
        getProductsByTreatmentType(checkedType, checkedTreatment); 
    });

    //handle treatment menu on S screen
    $(".show-treatments").click(function(){
        $(".treatments-list").toggleClass('hide');
        $(".show-treatments").toggleClass('hide');
        
        if($(".treatments-list").hasClass("hide")){
            $(".show-treatments span").text("Show treatments");
        }
        else{
            $(".show-treatments span").text("Hide treatments");             
        }
    });

$( document ).ajaxComplete(function() {
    sortProductsList();
});


function getProductsByTreatmentType(checkedType, checkedTreatment){
    
    $.getJSON("api/product/filter.php?fabric_type="+checkedType+"&fabric_treatment="+checkedTreatment+"&fabric_color="+checkedColor+"&fabric_new="+checkedNew+"", function(data){       
        if (data.records === undefined) {
            console.log("No products found");
        }
        else{
            $(data.records).each(function( index ) {
                //var product = data.records[index]; //single product
                listProducts(data, index);
            });
        }
        

    }).done(function() {
    
    });
}

}
